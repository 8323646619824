import { LatLng } from "../__generated__/proto/google/type/latlng"
import { Parking } from "../__generated__/proto/itech/motorist/pksha/v1/parking"
import * as config from "../config"

export type ParkingDetailState = {
  currentParking: Parking | null
  parkings: Parking[]
  currentPage: number
  mapCenter: LatLng
}

export const initialParkingDetailState: ParkingDetailState = {
  currentParking: null,
  parkings: [],
  currentPage: 1,
  mapCenter: config.DEFAULT_MAP_CENTER,
}
