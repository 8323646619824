// extracted by mini-css-extract-plugin
export var mainContainer = "parking-detail-module--mainContainer--2UryZ";
export var backToTopButton = "parking-detail-module--backToTopButton--1fHki";
export var parkingNameContainer = "parking-detail-module--parkingNameContainer--3vQjv";
export var parkingNameText = "parking-detail-module--parkingNameText--1MZ1_";
export var map = "parking-detail-module--map--YES_D";
export var mapPlaceholder = "parking-detail-module--mapPlaceholder--2kyTm";
export var infoContainer = "parking-detail-module--infoContainer---_vbX";
export var infoText = "parking-detail-module--infoText--r0-GZ";
export var parkingListContainer = "parking-detail-module--parkingListContainer--2pyyy";
export var parkingListTitle = "parking-detail-module--parkingListTitle--XPfAi";