import { useState, useEffect, useCallback, Dispatch } from "react"
import { Transaction } from "../domain/models"
import { TransactionService } from "../domain/services"
import { ListTransactionsUseCase } from "../domain/use-cases/list-transactions-use-case"

const transactionService = new TransactionService()
const listTransactionsUseCase = new ListTransactionsUseCase(transactionService)

export type UseTransactionProps = {
  fallback?: Dispatch<unknown>
  polling?: boolean
  interval?: number
}

type UseTransactionResult = {
  transaction: Transaction | undefined
  fetchTransaction: () => Promise<void>
}

export const useTransaction = ({
  fallback,
  polling = false,
  interval = 5000,
}: UseTransactionProps): UseTransactionResult => {
  const [transaction, setTransaction] = useState<Transaction>()

  const fetchTransaction = useCallback(async () => {
    try {
      const result = await listTransactionsUseCase.call({
        pageSize: 1,
        pageToken: "",
        parkingName: "",
        parkingRawId: "",
      })
      if (result.transactions.length === 0) {
        return
      }

      const txn = Transaction.fromProto(result.transactions[0])
      setTransaction(txn)
    } catch (e) {
      fallback?.(e)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      await fetchTransaction()
    })()

    if (polling && interval > 0) {
      const id = setInterval(async () => {
        await fetchTransaction()
      }, interval)

      return () => clearInterval(id)
    }
  }, [polling, interval, fetchTransaction])

  return { transaction, fetchTransaction }
}
