import React, { Dispatch, ReactElement } from "react"
import { LatLng } from "../__generated__/proto/google/type/latlng"
import { Parking } from "../__generated__/proto/itech/motorist/pksha/v1/parking"
import {
  GetDistanceUseCase,
  GetParkingCurrentFeeUseCase,
  SortParkingsUseCase,
} from "../domain/use-cases"
import {
  initialParkingDetailState,
  ParkingDetailState,
} from "./parking-detail-state"

const getDistanceUseCase = new GetDistanceUseCase()
const getParkingCurrentFeeUseCase = new GetParkingCurrentFeeUseCase()
const sortParkingsUseCase = new SortParkingsUseCase(
  getDistanceUseCase,
  getParkingCurrentFeeUseCase
)

type Action =
  | {
      type: "SET_CURRENT_PARKING"
      parking: Parking | null
    }
  | {
      type: "SET_PARKINGS"
      parkings: Parking[]
    }
  | {
      type: "SET_CURRENT_PAGE"
      currentPage: number
    }
  | {
      type: "SET_MAP_CENTER"
      mapCenter: LatLng
    }

const reduce = (
  state: ParkingDetailState,
  action: Action
): ParkingDetailState => {
  switch (action.type) {
    case "SET_CURRENT_PARKING":
      return {
        ...state,
        currentParking: action.parking,
        parkings: state.parkings.filter(p => p.name !== action.parking?.name),
      }
    case "SET_PARKINGS":
      return {
        ...state,
        parkings: sortParkingsUseCase
          .call({
            parkings: action.parkings,
            type: "DISTANCE",
            mapCenter: state.mapCenter,
          })
          .filter(p => p.name !== state.currentParking?.name),
      }
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.currentPage,
      }
    case "SET_MAP_CENTER":
      return {
        ...state,
        mapCenter: action.mapCenter,
        parkings: sortParkingsUseCase
          .call({
            parkings: state.parkings,
            type: "DISTANCE",
            mapCenter: action.mapCenter,
          })
          .filter(p => p.name !== state.currentParking?.name),
      }
  }
}

type ParkingDetailReducer = [ParkingDetailState, Dispatch<Action>]

const ParkingDetailReducerContext =
  React.createContext<ParkingDetailReducer | null>(null)

export const useParkingDetailReducer = (): ParkingDetailReducer => {
  const reducer = React.useContext(ParkingDetailReducerContext)
  if (reducer === null) {
    throw new Error(
      "useParkingDetailReducer must be used within ParkingDetailReducerProvider"
    )
  }
  return reducer
}

export const ParkingDetailReducerProvider = (props: {
  children: ReactElement
}): ReactElement => {
  const [state, dispatch] = React.useReducer(reduce, initialParkingDetailState)
  return (
    <ParkingDetailReducerContext.Provider value={[state, dispatch]}>
      {props.children}
    </ParkingDetailReducerContext.Provider>
  )
}
