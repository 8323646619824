import { getDefaultStore } from "jotai"
import {
  ListTransactionsRequest,
  ListTransactionsResponse,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import { authAtom } from "../../atoms"
import { AuthError, TransactionServiceType } from "../services"
import { UseCase } from "./use-case"

export class ListTransactionsUseCase
  implements
    UseCase<ListTransactionsRequest, Promise<ListTransactionsResponse>>
{
  constructor(readonly transactionService: TransactionServiceType) {}

  async call(
    request: ListTransactionsRequest
  ): Promise<ListTransactionsResponse> {
    const token = getDefaultStore().get(authAtom)
    if (!token) {
      throw new AuthError()
    }

    const result = await this.transactionService.listTransactions(
      token,
      request
    )
    if (result.isFailure()) {
      console.error(result.value)
      return {
        nextPageToken: "",
        transactions: [],
      }
    }

    return result.value
  }
}
