import * as config from "../../config"

// Documentation
// https://developers.google.com/actions-center/verticals/appointments/redirect/overview

export interface GoogleActionsCenterServiceType {
  storeToken(): void
  sendConversionTracking(): void
}

export class GoogleActionsCenterService
  implements GoogleActionsCenterServiceType
{
  // The token is passed as a query parameter in the URL from google maps.
  // https://developers.google.com/actions-center/verticals/appointments/redirect/integration-steps/conversion-tracking#collect-token
  private parseSearchToken() {
    const params = new URLSearchParams(location.search)
    return params.get("rwg_token")
  }

  // The token is stored in a cookie.
  // https://developers.google.com/actions-center/verticals/appointments/redirect/integration-steps/conversion-tracking#store-token
  storeToken(): void {
    const token = this.parseSearchToken()
    if (token == null) {
      return
    }
    document.cookie = `_rwg_token=${encodeURIComponent(
      token
    )};max-age=2592000;domain=${location.hostname};path=/`
  }

  // Loads the token from the cookie.
  loadToken(): string | undefined {
    const tokenCookie = document.cookie
      .split("; ")
      .find(row => row.startsWith("_rwg_token="))
    if (tokenCookie == null) {
      return
    }
    return decodeURIComponent(tokenCookie.split("=")[1])
  }

  // The token is sent to the Google Actions Center API.
  // https://developers.google.com/actions-center/verticals/appointments/redirect/integration-steps/conversion-tracking#return-conversion
  sendConversionTracking(): void {
    const token = this.loadToken()
    if (token == null) {
      return
    }

    fetch(config.GOOGLE_ACTIONS_CENTER_CONVERSION_TRACKING_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        conversion_partner_id: config.GOOGLE_ACTIONS_CENTER_PARTNER_ID,
        rwg_token: token,
        merchant_changed: 2, // always 2: https://developers.google.com/actions-center/verticals/appointments/redirect/integration-steps/conversion-tracking#return-merchant
      }),
    })
  }
}
